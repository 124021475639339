<template>
    <div>
        <PersonalDataCategories />
    </div>
</template>

<script>
import PersonalDataCategories from '@/components/settings/personalDataCategories/index.vue';

export default {
    name: 'SettingsPersonalDataCategories',
    components: {
        PersonalDataCategories,
    },
};
</script>
